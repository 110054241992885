import React from 'react';
import "./style.css";

const Footer = () => {
    return (
        <div className="footer-container">
           Made by Balance, 2020 - All rights reserved
        </div>
    )
}

export default Footer;