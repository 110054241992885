import React, { useState } from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import ItemsGrid from "../ItemsGrid/ItemsGrid";
import Footer from "../Footer/Footer";
import ItemDetail from "../ItemDetail/ItemDetail";
import CartCheckOut from "../CartCheckout/CartCheckout";
import AddPaymentMethod from "../AddPaymentMethod/AddPaymentMethod";
import data from "../../data.js";

const HomeContainer = () => {
  const [cart, setCart] = useState([]);

  const checkItemsInCart = itemData => {
    let exist = false;
    if (cart.length > 0) {
      cart.forEach(itemInCart => {
        if (itemInCart.name === itemData.name) {
          itemData.qty += 1;
          exist = true;
        }
      });
    }
    if (exist === false) {
      itemData.qty = 1;
      setCart([...cart, itemData]);
    }
  };

  return (
    <Router>
      <div className="all-app-container">
        <NavBar cart={cart} />
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route
          exact
          path="/home"
          render={() => (
            <ItemsGrid
              category="TOP SELLERS"
              data={data.home}
              cart={cart}
              setCart={checkItemsInCart}
            />
          )}
        />
        <Route
          exact
          path="/products/computers"
          render={() => (
            <ItemsGrid
              category="COMPUTERS"
              data={data.computers}
              cart={cart}
              setCart={checkItemsInCart}
            />
          )}
        />
        <Route
          exact
          path="/products/phones"
          render={() => (
            <ItemsGrid
              category="PHONES"
              data={data.phones}
              cart={cart}
              setCart={checkItemsInCart}
            />
          )}
        />
        <Route
          exact
          path="/products/tv_video"
          render={() => (
            <ItemsGrid
              category="TV & VIDEO"
              data={data.tv}
              cart={cart}
              setCart={checkItemsInCart}
            />
          )}
        />
        <Route
          exact
          path="/products/audio"
          render={() => (
            <ItemsGrid
              category="AUDIO"
              data={data.audio}
              cart={cart}
              setCart={checkItemsInCart}
            />
          )}
        />
        <Route
          path="/products/computers/:id"
          render={(props) => (
            <ItemDetail
              {...props}
              cart={cart}
              setCart={checkItemsInCart}
              category="computers"
            />
          )}
        />
        <Route
          path="/products/phones/:id"
          render={(props) => (
            <ItemDetail
              {...props}
              cart={cart}
              setCart={checkItemsInCart}
              category="phones"
            />
          )}
        />
        <Route
          path="/products/tv/:id"
          render={(props) => (
            <ItemDetail
              {...props}
              cart={cart}
              setCart={checkItemsInCart}
              category="tv"
            />
          )}
        />
        <Route
          path="/products/audio/:id"
          render={(props) => (
            <ItemDetail
              {...props}
              cart={cart}
              setCart={checkItemsInCart}
              category="audio"
            />
          )}
        />
        <Route
          exact
          path="/checkout"
          render={() => <CartCheckOut cart={cart} setCart={setCart}/>}
        />
        <Route
          exact
          path="/payment-method"
          render={() => <AddPaymentMethod />}
        />
        <Footer />
      </div>
    </Router>
  );
};

export default HomeContainer;
