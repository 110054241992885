import { useQueryParams } from "./query-params.hook";
import { CHECKOUT_V1_SDK, CHECKOUT_V2_1_SDK, CHECKOUT_V2_SDK } from "../config/config";
import {useExternalScript} from "./external-script.hook";

const useSdkScript = () => {
  const { v2, v1 } = useQueryParams();

  if (v1) {
    return CHECKOUT_V1_SDK;
  }
  if (v2) {
    return CHECKOUT_V2_SDK;
  }
  return CHECKOUT_V2_1_SDK;
}

export const useCheckoutScript = () => {
  const script = useSdkScript();
  const state = useExternalScript(script);

  return state === "ready";
}