import React from "react";
import "./style.css";
import logo from "../../images/logo.png";
import { Button, Input } from "antd";
import { ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";
import {LinkWithParams} from "../LinkWithParams/LinkWithParams";

const NavBar = ({ cart }) => {
  const { Search } = Input;

  return (
    <div className="navbar-container">
    <header className="App-header">
      <div className="navbar-header-cont">
        <div className="navbar-name-and-icon"
        >
          <div className="navbar-icon-cont">
            <img
              src={logo}
              alt="logo"
            />
          </div>
          <p className="title">COMPUTER CENTER</p>
        </div>
        <div className="navbar-buttons">
          <Search
            className="input-search navbar"
            placeholder="Search some product..."
            // onSearch={(value) => console.log(value)}
          />
          <LinkWithParams to="/checkout">
            <Button
              className="cart-btn"
              shape="circle"
              icon={<ShoppingCartOutlined />}
              size="large"
            />
          </LinkWithParams>
          <div className="cart-number">{cart.length}</div>
          <LinkWithParams to="/payment-method">
            <Button
              className="login-btn"
              shape="circle"
              icon={<UserOutlined />}
              size="large"
            />
          </LinkWithParams>
        </div>
      </div>
      </header>
      <div className="navbar-category-buttons">
        <LinkWithParams to="/home">
          <Button className="category-btn">Home</Button>
        </LinkWithParams>
        <LinkWithParams to="/products/computers">
          <Button className="category-btn">Computers</Button>
        </LinkWithParams>
        <LinkWithParams to="/products/phones">
          <Button className="category-btn">Phones</Button>
        </LinkWithParams>
        <LinkWithParams to="/products/tv_video">
          <Button className="category-btn">TV & Video</Button>
        </LinkWithParams>
        <LinkWithParams to="/products/audio">
          <Button className="category-btn">Audio</Button>
        </LinkWithParams>
      </div>
    </div>
  );
};

export default NavBar;
