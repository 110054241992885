import React from 'react';
import HomeContainer from './components/HomeContainer/HomeContainer'
import './App.css';
import { useCheckoutScript } from "./hooks/checkout-script.hook";

function App() {
  const loadApp = useCheckoutScript();

  if (!loadApp) {
      return false;
  }

  return (
    <div className="App">
      <HomeContainer />
    </div>
  );
}

export default App;
