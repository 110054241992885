const data = {
  home: [
    {
      id: 1,
      category: `computers`,
      name: `Apple - MacBook Air 13.3" Laptop with Touch ID - Intel Core i3 - 8GB Memory - 256GB Solid State Drive - Gold`,
      stock: 4,
      price: 949.99,
      img: `computers1`,
      specs: [
        `Stunning 13.3" Retina display with True Tone technology`,
        `Backlit Magic Keyboard and Touch ID`,
        `Tenth-generation Intel Core i3 `,
        `Intel Iris Plus Graphics`,
        `Fast SSD storage`,
        `8GB of memory`,
        `Stereo speakers with wider stereo sound`,
        `Two Thunderbolt 3 (USB Type-C) ports`,
        `Up to 11 hours of battery life`,
        `Force Touch trackpad`,
        `802.11ac Wi-Fi`,
        `Available in gold, space gray, and silver`,
      ],
      description: `Available in silver, space gray, and gold, the latest MacBook Air features a stunning Retina display with True Tone technology, Touch ID, a backlit Magic Keyboard, and a Force Touch trackpad - all housed in a thin and light iconic wedge design made from 100 percent recycled aluminum.² And with 11-hour battery life, it's a do-it-all notebook that goes all day long.`,
    },
    {
      id: 2,
      category: `phones`,
      name: `Google - Pixel 4a 128GB (Unlocked) - Just Black`,
      stock: 3,
      price: 299.99,
      img: `phones2`,
      specs: [
        `Qualcomm Snapdragon 730 processor`,
        `Universal Unlocked`,
        `Rich photos at a modest price`,
        `5.8-inch FHD+ OLED screen`,
        `128GB storage`,
      ],
      description: `Meet Pixel. Possibilities by you. Phone by Google. The power of Google, at your fingertips. Every touch, every interaction, every moment, made easy. Pixel brings you the Google Assistant. Ask it questions. Tell it to do things. It's your own personal Google, always ready to help. Just start with “Ok Google”. With unlimited online storage for your photos and videos, you'll never have to delete an old memory to make room for a new one. And Pixel keeps everything safe and secure: photos, videos, music, contacts, texts, and more. With a best-ever 89 DxOMark Mobile score, Pixel's camera lets you take brilliant photos in low light, bright light or any light. Every moment, every memory, captured in bright, beautiful detail. Duo brings you face-to-face with the people who matter most, across Android and iPhone, so you're always in touch. With the new Knock Knock feature, you can even see the caller before you pick up. Fast and easy to use, Pixel provides a clean, bloat-free experience with no unwanted apps. And, for a quick charge, the USB-C charger gives you up to 7 hours of battery life in just 15 minutes.`,
    },
    {
      id: 3,
      category: `tv`,
      name: `Samsung - 43" Class 7 Series LED 4K UHD Smart Tizen TV`,
      stock: 18,
      price: 279.99,
      img: `tv3`,
      specs: [
        `Crystal Processor 4K`,
        `Universal guide`,
        `4K Ultra HD (2160p resolution)`,
        `Boundless design`,
        `Motion Rate 120`,
      ],
      description: `Upgrade your home entertainment setup with this 43-inch Samsung LED Smart TV. Tizen OS provides access to popular streaming services, while the 4K Ultra HD resolution delivers true-to-life picture quality. This Samsung LED Smart TV is compatible with several virtual assistants for voice control, and the HDMI and USB inputs connect to external sources.`,
    },
    {
      id: 4,
      category: `audio`,
      name: `Sonos - Move Smart Portable Wi-Fi and Bluetooth Speaker with Alexa and Google Assistant - Black`,
      stock: 6,
      price: 399.99,
      img: `audio2`,
      specs: [
        `Perfect sound outdoors`,
        `Built-in Google Assistant and Amazon Alexa`,
        `Connect wirelessly from distance`,
      ],
      description: `Play your favorite tunes anywhere with this weatherproof and drop-resistant Sonos Move portable speaker. The Trueplay automatic tuning adapts and balances the sound based on where you are and what you're listening to. This Sonos Move portable speaker incorporates built-in support for Wi-Fi, Bluetooth, Airplay and the Sonos app to let you stream audio from a smart device.`,
    },
    {
      id: 5,
      category: `computers`,
      name: `HP - 11.6" Chromebook - Intel Celeron - 4GB Memory - 32GB eMMC Flash Memory - Ash Gray`,
      stock: 3,
      price: 219.0,
      img: `computers5`,
      specs: [
        `Google Chrome OS`,
        `11.6" display`,
        `Intel® Celeron® processor N3350`,
        `4GB system memory for basic multitasking`,
        `32GB eMMC flash memory`,
        `Built-in cloud support`,
        `Intel® HD Graphics 500`,
      ],
      description: `HP Chromebook: Browse the internet and complete daily computing tasks quickly with this HP Chromebook. An Intel Celeron N3350 processor and 4GB of RAM let you work with multiple windows simultaneously for increased productivity, and 32GB of flash storage offer swift startup times. This HP Chromebook has Intel HD 500 integrated graphics for rendering fine-textured visuals on the 11.6-inch HD display, and the sleek design allows increased portability.`,
    },
    {
      id: 8,
      category: `audio`,
      name: `Sony - WH-XB900N Wireless Noise Cancelling Over-the-Ear Headphones - Black`,
      stock: 8,
      price: 249.99,
      img: `audio8`,
      specs: [
        `3.5mm gold-plated connector`,
        `Bose Connect app`,
        `Bluetooth 4.2 interface with NFC`,
      ],
      description: `Experience an immersive acoustic performance with these Sony XB Extra Bass wireless headphones. The noise-isolating fit mutes ambient noises, while the lithium-ion battery offers up to 30 hours of playtime, keeping you entertained during long-distance travels. These Sony XB Extra Bass wireless headphones have a touch sensor for convenient control.`,
    },
    {
      id: 7,
      category: `tv`,
      name: `LG - 77" Class CX Series OLED 4K UHD Smart webOS TV`,
      stock: 4,
      price: 3699.99,
      img: `tv7`,
      specs: [
        `α9 Gen 3 AI Processor 4K`,
        `Pixel Level Dimming`,
        `LG ThinQ AI, the Google Assistant and Alexa`,
        `Cinema HDR (Dolby Vision, HDR10, HLG)`,
        `Dolby Vision IQ`,
      ],
      description: `Stream your favorite TV series and movies with this 77-inch LG OLED television. Compatibility with Alexa offers convenient hands-free control, while 4K UHD resolution delivers vivid captivating imagery. This LG OLED television is Wi-Fi-enabled and integrates smart features so you can watch your favorite online videos and the recent blockbusters.`,
    },
    {
      id: 6,
      category: `phones`,
      name: `Apple - Pre-Owned iPhone 8 with 64GB Memory Cell Phone (Unlocked) - Space Gray`,
      stock: 1,
      price: 299.99,
      img: `phones6`,
      specs: [
        `Apple A11 Bionic chip with M11 motion coprocessor`,
        `iOS 11`,
        `Universal Unlocked`,
        `4G LTE speed`,
        `4.7" Retina touch screen with IPS technology`,
        `12.0MP rear-facing camera`,
      ],
      description: `Keep in touch with family and friends with this unlocked Apple iPhone 8 smartphone. The powerful A11 Bionic chip combined with iOS ensures lag-free performance, while the 7MP front-facing camera lets you video call in HD quality. This preowned Apple iPhone 8 smartphone has 64GB of memory for storing large files and plenty of apps.`,
    },
  ],
  computers: [
    {
      id: 1,
      category: `computers`,
      name: `Apple - MacBook Air 13.3" Laptop - Intel Core i3 - 8GB Memory - 256GB Solid State Drive (Latest Model) - Gold`,
      stock: 4,
      price: 949.99,
      img: `computers1`,
      specs: [
        `Stunning 13.3" Retina display with True Tone technology`,
        `Backlit Magic Keyboard and Touch ID`,
        `Tenth-generation Intel Core i3 `,
        `Intel Iris Plus Graphics`,
        `Fast SSD storage`,
        `8GB of memory`,
        `Stereo speakers with wider stereo sound`,
        `Two Thunderbolt 3 (USB Type-C) ports`,
        `Up to 11 hours of battery life`,
        `Force Touch trackpad`,
        `802.11ac Wi-Fi`,
        `Available in gold, space gray, and silver`,
      ],
      description: `Available in silver, space gray, and gold, the latest MacBook Air features a stunning Retina display with True Tone technology, Touch ID, a backlit Magic Keyboard, and a Force Touch trackpad - all housed in a thin and light iconic wedge design made from 100 percent recycled aluminum.² And with 11-hour battery life, it's a do-it-all notebook that goes all day long.`,
    },
    {
      id: 2,
      category: `computers`,
      name: `HP - 15.6" Touch-Screen Laptop - Intel Core i5 - 12GB Memory - 256GB SSD - Natural Silver`,
      stock: 18,
      price: 619.99,
      img: `computers2`,
      specs: [
        `Windows 10 operating system`,
        `15.6" Micro-edge touchscreen display`,
        `10th Generation Intel® Core™ i5-1035G1 processor`,
        `12GB system memory for full-power multitasking`,
        `256GB Solid State Drive`,
        `Intel® UHD Graphics`,
      ],
      description: `Designed to keep you productiveand entertained from anywhere,the HP 15-inch diagonal laptopcombines long lasting battery lifewith a thin and portable, microedgebezel design.`,
    },
    {
      id: 3,
      category: `computers`,
      name: `ASUS - VivoBook S15 15.6" Laptop - Intel Core i5 - 8GB Memory - 512GB SSD - Punk Pink`,
      stock: 1,
      price: 549.99,
      img: `computers3`,
      specs: [
        `Windows 10 operating system`,
        `14" Full HD display`,
        `Ryzen 5`,
        `8GB system memory for advanced multitasking`,
        `Solid State Drive (PCI-e)`,
        `NVIDIA GeForce MX350 graphics`,
        `Weighs 2.65 lbs. and measures 0.7" thin`,
      ],
      description: `ASUS ZenBook 14 Q407IQ Laptop: Complete urgent projects on the go with this ASUS ZenBook laptop. The 14-inch Full HD display provides stunning visuals, while the AMD Ryzen 5 processor and 8GB of RAM power through everyday software and support smooth multitasking. This Wi-Fi and Bluetooth-enabled ASUS ZenBook laptop has a 256GB SSD for rapid boots.`,
    },
    {
      id: 4,
      category: `computers`,
      name: `Lenovo - IdeaPad 3 15" Laptop - Intel Core i3-1005G1 - 8GB Memory - 256GB SSD - Platinum Grey`,
      stock: 8,
      price: 449.99,
      img: `computers4`,
      specs: [
        `Windows 10 operating system`,
        `15.6" Full HD display`,
        `10th Gen Intel® Core™ i3-1005G1 processor`,
        `8GB system memory for advanced multitasking`,
        `Solid State Drive (PCI-e)`,
        `Intel® UHD Graphics`,
        `Weighs 4.07 lbs. and measures 0.78" thin`,
      ],
      description: `Engineered for long-lasting performance, the Lenovo™ IdeaPad™ 3 delivers powerful performance in a laptop that's perfect for your everyday tasks, with features that you can depend on.`,
    },
    {
      id: 5,
      category: `computers`,
      name: `HP - 11.6" Chromebook - Intel Celeron - 4GB Memory - 32GB eMMC Flash Memory - Ash Gray`,
      stock: 3,
      price: 219.0,
      img: `computers5`,
      specs: [
        `Google Chrome OS`,
        `11.6" display`,
        `Intel® Celeron® processor N3350`,
        `4GB system memory for basic multitasking`,
        `32GB eMMC flash memory`,
        `Built-in cloud support`,
        `Intel® HD Graphics 500`,
      ],
      description: `HP Chromebook: Browse the internet and complete daily computing tasks quickly with this HP Chromebook. An Intel Celeron N3350 processor and 4GB of RAM let you work with multiple windows simultaneously for increased productivity, and 32GB of flash storage offer swift startup times. This HP Chromebook has Intel HD 500 integrated graphics for rendering fine-textured visuals on the 11.6-inch HD display, and the sleek design allows increased portability.`,
    },
    {
      id: 6,
      category: `computers`,
      name: `Acer - 15.6" Chromebook - Intel Celeron N4000 - 4GB Memory - 32GB Flash Drive - Silver`,
      stock: 1,
      price: 259.99,
      img: `computers6`,
      specs: [
        `Google Chrome OS`,
        `15.6" Full HD display`,
        `Intel® UHD Graphics`,
        `32GB eMMC flash memory`,
        `Built-in cloud support`,
        `4GB system memory for basic multitasking`,
      ],
      description: `The stunningly elegant Acer Chromebook 315, with its 15.6” screen, is the ideal replacement for a desktop. The large screen and upward facing speakers also make it perfect for watching videos and video conferencing.`,
    },
    {
      id: 7,
      category: `computers`,
      name: `Apple - MacBook Pro - 16" Display with Touch Bar - Intel Core i7 - 16GB Memory - 512GB SSD - Space Gray`,
      stock: 4,
      price: 2399.99,
      img: `computers7`,
      specs: [
        `
          Ninth-generation 6-core Intel Core i7 processor`,
        `Stunning 16" Retina display`,
        `Touch Bar and Touch ID`,
        `AMD Radeon Pro 5300M graphics with GDDR6 memory`,
        `Ultrafast SSD`,
        `Intel UHD Graphics 630`,
        `Six-speaker system with force-canceling woofers`,
        `Four Thunderbolt 3 (USB-C) ports`,
        `Up to 11 hours of battery life`,
        `802.11ac Wi-Fi`,
      ],
      description: `Expand your view of everything on MacBook Pro thanks to a larger 16" Retina display with sharper pixel resolution and support for millions of colors.¹ Harness the power of 6-core processors and AMD Radeon Pro 5000M series graphics with 4GB of GDDR6 memory, together with an optimized thermal architecture for groundbreaking performance. Featuring 16GB memory and 512GB of storage.³ Touch ID and the Touch Bar. And all-day battery life.² Designed for pros who put performance above all else, MacBook Pro gives you the power to accomplish anything, anywhere.`,
    },
    {
      id: 8,
      category: `computers`,
      name: `HP - Stream 11.6" Laptop - Intel Celeron - 4GB Memory - 64GB eMMC Flash Memory - Diamond White`,
      stock: 2,
      price: 219.99,
      img: `computers8`,
      specs: [
        `Windows 10 operating system`,
        `11" High-definition display`,
        `Dual-core Intel® Celeron® processor`,
        `4GB system memory for basic multitasking`,
        `64GB eMMC flash memory`,
        `Intel® UHD Graphics`,
        `Tuned stereo speakers`,
      ],
      description: `Post, play, and stay productive for up to 13 hours and 15 minutes with the power of a Windows PC. HP Stream lets you do it all in a design that’s affordable, portable, and incredibly stylish.`,
    },
  ],
  phones: [
    {
      id: 1,
      category: `phones`,
      name: `Samsung - Galaxy S20 FE 5G 128GB (Unlocked) - Cloud Navy`,
      stock: 5,
      price: 549.99,
      img: `phones1`,
      specs: [
        `Hyperfast Processor`,
        `Galaxy 5G`,
        `Triple lens camera and 3X optical zoom`,
        `Night Mode`,
        `Single Take AI`,
        `32MP Selfie Camera`,
      ],
      description: `We get you. The standout in the crowd, the one with relentless passion. The one that stays true to yourself and connected to those around you. You deserve a phone that delivers industry-leading tech and allows you to express yourself in way you choose. With bold color options, pro-grade camera, 120Hz display and an all-day intelligent battery¹ that charges up in minutes², the Galaxy S20 FE 5G delivers uncompromised innovation. This is unexpected. ¹Based on average battery life under typical usage conditions. Average expected performance based on typical use. Actual battery life depends on factors such as network, features selected, frequency of calls, and voice, data, and other application usage patterns. Results may vary. ²When using 25W charger, sold separately.`,
    },
    {
      id: 2,
      category: `phones`,
      name: `Google - Pixel 4a 128GB (Unlocked) - Just Black`,
      stock: 3,
      price: 299.99,
      img: `phones2`,
      specs: [
        `Qualcomm Snapdragon 730 processor`,
        `Universal Unlocked`,
        `Rich photos at a modest price`,
        `5.8-inch FHD+ OLED screen`,
        `128GB storage`,
      ],
      description: `Meet Pixel. Possibilities by you. Phone by Google. The power of Google, at your fingertips. Every touch, every interaction, every moment, made easy. Pixel brings you the Google Assistant. Ask it questions. Tell it to do things. It's your own personal Google, always ready to help. Just start with “Ok Google”. With unlimited online storage for your photos and videos, you'll never have to delete an old memory to make room for a new one. And Pixel keeps everything safe and secure: photos, videos, music, contacts, texts, and more. With a best-ever 89 DxOMark Mobile score, Pixel's camera lets you take brilliant photos in low light, bright light or any light. Every moment, every memory, captured in bright, beautiful detail. Duo brings you face-to-face with the people who matter most, across Android and iPhone, so you're always in touch. With the new Knock Knock feature, you can even see the caller before you pick up. Fast and easy to use, Pixel provides a clean, bloat-free experience with no unwanted apps. And, for a quick charge, the USB-C charger gives you up to 7 hours of battery life in just 15 minutes.`,
    },
    {
      id: 3,
      category: `phones`,
      name: `Samsung - Galaxy A11 32GB (Unlocked) - Black`,
      stock: 9,
      price: 129.99,
      img: `phones3`,
      specs: [
        `Octa-core processor`,
        `Triple Camera with 13MP Lens`,
        `6.4" Infinity Display`,
        `Expandable Memory`,
        `Long-Lasting Battery`,
      ],
      description: `The Samsung Galaxy A11 combines smartphone essentials with the trusted reliability of Samsung. Take crisp, clear photos and videos with our powerful triple lens camera. Enjoy cinematic clarity on our 6.4" edge-to-edge display¹. Keep going with a long-lasting battery² that keeps going with you throughout the day.¹Measured diagonally, the screen size is 6.4" in the full rectangle and 6.3" with accounting for the rounded corners. Actual viewable area is less due to the rounded corners and camera lens.²Based on average battery life under typical usage conditions. Average expected performance based on typical use. Actual battery life depends on factors such as network, features selected, frequency of calls, and voice, data, and other application usage patterns. Results may vary.`,
    },
    {
      id: 4,
      category: `phones`,
      name: `Motorola - moto g Fast with 32GB Memory Cell Phone (Unlocked) - Pearl White`,
      stock: 6,
      price: 99.99,
      img: `phones4`,
      specs: [
        `Qualcomm Snapdragon 665 processor`,
        `Universal unlocked`,
        `4G LTE speed`,
        `16.0MP triple camera system`,
        `6.4" HD+ Max Vision display`,
      ],
      description: `Stay connected and entertained with this Motorola moto g fast smartphone. The Qualcomm Snapdragon 665 processor and 3GB of RAM let you flip through apps seamlessly, while the 32GB of internal storage accommodates your downloads and photos. This Motorola moto g fast smartphone features a 6.4-inch HD+ Max Vision display for crisp visuals, and the 4000 mAh rechargeable battery delivers up to two days of use.`,
    },
    {
      id: 5,
      category: `phones`,
      name: `Samsung - Galaxy A51 with 128GB Memory Cell Phone (Unlocked) - Prism Crush Black`,
      stock: 12,
      price: 349.99,
      img: `phones5`,
      specs: [
        `Octa-core 2.3GHz processor with 4GB of RAM`,
        `Quad-camera setup`,
        `4G LTE speed`,
        `Super AMOLED 6.5" Infinity-O display`,
        `128GB internal memory plus microSD slot`,
      ],
      description: `Communicate effectively with this unlocked Samsung A51 smartphone. The 4GB RAM ensures fast processing speed and increased performance, while the 128GB storage offers ample space for media and documents. This Samsung A51 smartphone has a 4000 mAh battery that provides enough power for all-day use, and Bluetooth connectivity lets you access music and other media wirelessly.`,
    },
    {
      id: 6,
      category: `phones`,
      name: `Apple - iPhone 8 with 64GB Memory Cell Phone (Unlocked) - Space Gray`,
      stock: 1,
      price: 399.99,
      img: `phones6`,
      specs: [
        `Apple A11 Bionic chip with M11 motion coprocessor`,
        `iOS 11`,
        `Universal Unlocked`,
        `4G LTE speed`,
        `4.7" Retina touch screen with IPS technology`,
        `12.0MP rear-facing camera`,
      ],
      description: `Keep in touch with family and friends with this unlocked Apple iPhone 8 smartphone. The powerful A11 Bionic chip combined with iOS ensures lag-free performance, while the 7MP front-facing camera lets you video call in HD quality. This preowned Apple iPhone 8 smartphone has 64GB of memory for storing large files and plenty of apps.`,
    },
    {
      id: 7,
      category: `phones`,
      name: `Samsung - Galaxy S20+ 5G Enabled 128GB - Cosmic Black (Verizon)`,
      stock: 4,
      price: 259.99,
      img: `phones7`,
      specs: [
        `Qualcomm Snapdragon 865 5G mobile platform`,
        `Android 10 OS`,
        `5G* speed`,
        `6.7" Infinity-O Quad HD+ Dynamic AMOLED 2.0 display`,
        `128GB internal memory plus microSD slot`,
        `12.0MP wide, 64.0MP telephoto, 12.0MP ultrawide rear-facing cameras and rear depth-sensing camera`,
      ],
      description: `Samsung Galaxy S20+ 5G Cell Phone for Verizon: Surf the Internet at high speeds with this cosmic black Samsung Galaxy S20+ 5G smartphone for Verizon. The 6.7-inch Dynamic AMOLED display provides bright, detailed visuals, while the 4500 mAh battery offers 34 hours of use on a single charge. This Samsung Galaxy S20+ 5G smartphone has 12GB of RAM for effective multitasking, and the 128GB internal storage offers ample space for files and applications. Backward-compatible with 4G networks.`,
    },
    {
      id: 8,
      category: `phones`,
      name: `Motorola - Moto One Action with 128GB Memory Cell Phone (Unlocked) - Denim Blue`,
      stock: 2,
      price: 149.99,
      img: `phones8`,
      specs: [
        `Octa-core 2.2GHz processor with 4GB of RAM`,
        `Android 9`,
        `4 LTE speed`,
        `Universal Unlocked`,
        `128GB internal memory plus microSD slot`,
        `6.34" IPS touch screen`,
      ],
      description: `Experience fast connectivity with this denim blue Motorola One Action smartphone. The 4GB of RAM and octa-processor ensure high-speed responses, while the 12MP front and triple rear cameras shoot flawless HD photos and 4K videos. This 128GB Motorola One Action smartphone features a 3500 mAh lithium-polymer battery to keep you available and a fingerprint reader for secure access.`,
    },
  ],
  tv: [
    {
      id: 1,
      category: `tv`,
      name: `LG - 86" Class UN8500 Series LED 4K UHD Smart webOS TV`,
      stock: 5,
      price: 1899.99,
      img: `tv1`,
      specs: [
        `4K IPS Display`,
        `α7 Gen 3 Processor 4K`,
        `Dolby Vision IQ and Dolby Atmos`,
        `FilmMaker mode`,
        `85.6” screen`,
        `Connect up to two select speakers for true surround experience`,
      ],
      description: `Immerse yourself into the action with this 86-inch LG LED Smart TV. The wide 4K IPS display produces clarity, color, and detail in every scene, while Dolby Vision IQ automatically adjusts the picture depending on the lighting of your room for quality viewing. This LG LED Smart TV features over 180 IP streaming channels for endless variety and Dolby Atmos for a complete multidimensional surround sound experience.`,
    },
    {
      id: 2,
      category: `tv`,
      name: `Westinghouse - 50" Class LED Full HD TV`,
      stock: 9,
      price: 199.99,
      img: `tv2`,
      specs: [
        `Streaming player ready`,
        `Full HD (1080p resolution)`,
        `LED backlit LCD TV`,
        `Wide 178° Viewing Angle`,
        `1 USB input`,
      ],
      description: `Enjoy an immersive viewing experience with this 50-inch Westinghouse Full HD TV. The HDMI and USB ports offer versatile connectivity with a wide range of devices, while the 178-degree viewing angle offers bright, accurate images from almost any direction. This Westinghouse Full HD TV has a 1200:1 contrast ration for delivering sharp, detailed visuals, and the pair of 8W speakers produce powerful sound.`,
    },
    {
      id: 3,
      category: `tv`,
      name: `Insignia™ - 43" Class LED Full HD TV Smart`,
      stock: 10,
      price: 149.99,
      img: `tv3`,
      specs: [
        `42.5" screen`,
        `1080p resolution for stunning HD images`,
        `LED TVs perform well in all lighting conditions`,
        `Advanced TV sound`,
        `3 HDMI inputs for the best home theater connection`,
      ],
      description: `Watch your favorite Netflix series in vivid color on this 43-inch Insignia LED TV. Its direct backlight illuminates the screen uniformly for sharper contrast, and media devices can be connected through a USB port and three HDMI inputs. This Insignia LED TV has a 1080p screen resolution for crisp, clear images.`,
    },
    {
      id: 4,
      category: `tv`,
      name: `Samsung - 65" Class 7 Series LED 4K UHD Smart Tizen TV`,
      stock: 5,
      price: 529.99,
      img: `tv4`,
      specs: [
        `Crystal Processor 4K`,
        `4K Ultra HD (2160p resolution)`,
        `Boundless design`,
        `Motion Rate 120`,
        `Universal guide`,
      ],
      description: `Enhance your viewing experience with this 65-inch Samsung 4K UHD smart TV. The HDR technology and 4K UHD resolution render sharp details and realistic colors, while a Crystal processor delivers exceptional picture quality on the flat-panel display. This Bluetooth-enabled Samsung 4K UHD smart TV is voice controllable for hands-free operation and seamless wireless streaming.`,
    },
    {
      id: 5,
      category: `tv`,
      name: `Westinghouse - 32" Class LED HD Smart Roku TV`,
      stock: 7,
      price: 109.99,
      img: `tv5`,
      specs: [
        `HD (720p resolution)`,
        `Voice search & control`,
        `Dolby Audio`,
        `Smart TV with access to streaming services for countless entertainment options`,
        `Manage the viewing experience for your kids`,
      ],
      description: `Stream online content effortlessly on this 32-inch Westinghouse HD smart TV. Dolby Audio technology ensures quality sound production through the pair of built-in 5W speakers, and the 6.5 ms response time minimizes motion blur and ghosting for smooth visuals. This Westinghouse HD smart TV has a 170-degree viewing angle for delivering bright, detailed images regardless of your vantage point.`,
    },
    {
      id: 6,
      category: `tv`,
      name: `Samsung - 43" Class 7 Series LED 4K UHD Smart Tizen TV`,
      stock: 18,
      price: 279.99,
      img: `tv6`,
      specs: [
        `Crystal Processor 4K`,
        `Universal guide`,
        `4K Ultra HD (2160p resolution)`,
        `Boundless design`,
        `Motion Rate 120`,
      ],
      description: `Upgrade your home entertainment setup with this 43-inch Samsung LED Smart TV. Tizen OS provides access to popular streaming services, while the 4K Ultra HD resolution delivers true-to-life picture quality. This Samsung LED Smart TV is compatible with several virtual assistants for voice control, and the HDMI and USB inputs connect to external sources.`,
    },
    {
      id: 7,
      category: `tv`,
      name: `LG - 77" Class CX Series OLED 4K UHD Smart webOS TV`,
      stock: 4,
      price: 3699.99,
      img: `tv7`,
      specs: [
        `α9 Gen 3 AI Processor 4K`,
        `Pixel Level Dimming`,
        `LG ThinQ AI, the Google Assistant and Alexa`,
        `Cinema HDR (Dolby Vision, HDR10, HLG)`,
        `Dolby Vision IQ`,
      ],
      description: `Stream your favorite TV series and movies with this 77-inch LG OLED television. Compatibility with Alexa offers convenient hands-free control, while 4K UHD resolution delivers vivid captivating imagery. This LG OLED television is Wi-Fi-enabled and integrates smart features so you can watch your favorite online videos and the recent blockbusters.`,
    },
    {
      id: 8,
      category: `tv`,
      name: `Samsung - 70" Class 6 Series LED 4K UHD Smart Tizen TV`,
      stock: 5,
      price: 599.99,
      img: `tv8`,
      specs: [
        `PurColor`,
        `69.5" screen`,
        `4K UHD`,
        `LED TVs perform well in all lighting conditions`,
        `Motion Rate 120`,
      ],
      description: `Experience the vibrant colors and clarity of this 70-inch Samsung ultra HD smart TV. Dolby audio, four picture modes and a smooth picture on fast-action bring lifelike cinematic immersion to your living room. Access the internet with Smart Hub, view photos or listen to music. This Samsung ultra HD smart TV has four times the resolution of Full HD for a crisp, sharp picture no matter where you're sitting in the room.`,
    },
  ],
  audio: [
    {
      id: 1,
      category: `audio`,
      name: `Beats by Dr. Dre - Solo³ The Beats Icon Collection Wireless On-Ear Headphones - Satin Gold`,
      stock: 2,
      price: 199.99,
      img: `audio1`,
      specs: [
        `Connect via Class 1 Bluetooth®`,
        `Up to 40 hours of battery life for multiday use`,
        `The award-winning sound and design`,
      ],
      description: `With up to 40 hours of battery life, Beats Solo³ Wireless is your perfect everyday headphone. With Fast Fuel, a 5-minute charge gives you 3 hours of playback. Enjoy award-winning Beats sound with Class 1 Bluetooth® wireless listening freedom. The on-ear, cushioned ear cups are adjustable so you can customize your fit for all-day comfort.`,
    },
    {
      id: 2,
      category: `audio`,
      name: `Sonos - Move Smart Portable Wi-Fi and Bluetooth Speaker with Alexa and Google Assistant - Black`,
      stock: 6,
      price: 399.99,
      img: `audio2`,
      specs: [
        `Perfect sound outdoors`,
        `Built-in Google Assistant and Amazon Alexa`,
        `Connect wirelessly from distance`,
      ],
      description: `Play your favorite tunes anywhere with this weatherproof and drop-resistant Sonos Move portable speaker. The Trueplay automatic tuning adapts and balances the sound based on where you are and what you're listening to. This Sonos Move portable speaker incorporates built-in support for Wi-Fi, Bluetooth, Airplay and the Sonos app to let you stream audio from a smart device.`,
    },
    {
      id: 3,
      category: `audio`,
      name: `Apple - AirPods with Wireless Charging Case (Latest Model) - White`,
      stock: 9,
      price: 159.99,
      img: `audio3`,
      specs: [
        `Easy setup for all your Apple devices`,
        `Quick access to Siri by saying "Hey Siri"`,
        `Rich, high-quality audio and voice`,
      ],
      description: `
      The new AirPods - complete with Wireless Charging Case - combine the intelligent design with breakthrough technology and crystal clear sound. Powered by the new Apple H1 headphone chip, AirPods now feature hands-free access to Siri using just your voice. And up to 3 hours of talk time on a single charge.`,
    },
    {
      id: 4,
      category: `audio`,
      name: `Klip5sch - Reference 5.25" 60W 2-Way Powered Monitors (Pair) - Black`,
      stock: 1,
      price: 405.99,
      img: `audio4`,
      specs: [
        `120W total system power`,
        `Internal amplifier`,
        `5.25" copper spun magnetically shielded IMG woofer`,
      ],
      description: `Bring down the house with these Klipsch Reference Premier powered monitors. Designed for easy setup, these versatile speakers offer extensive connectivity with Bluetooth technology and digital optical, analog RCA and USB inputs. These Klipsch Reference Premier powered monitors feature Dynamic Bass EQ technology for powerful bass at any listening volume.`,
    },
    {
      id: 5,
      category: `audio`,
      name: `Bose - QuietComfort 35 II Wireless Noise Cancelling Headphones - Silver`,
      stock: 5,
      price: 299.99,
      img: `audio5`,
      specs: [
        `High-capacity battery`,
        `Bose Connect app`,
        `Active noise cancellation`,
      ],
      description: `Bose QuietComfort 35 II Wireless Noise Cancelling Headphones are over-ear headphones from Bose featuring world-class noise cancelling technology, and now they're optimized with Amazon Alexa and the Google Assistant. Enjoy balanced audio performance at any volume with volume-optimized EQ. And you can make or receive clear phone calls, even in loud environments with the noise reduction dual-microphone system. Connect to your devices wirelessly with Bluetooth and NFC pairing, with voice prompts for easy Bluetooth pairing. Thanks to Amazon Alexa and the Google Assistant you can keep in touch, get answers and manage your day using just your voice with one-button access to the voice assistant of your choice. You can also access your phone's default virtual assistant, like Siri. Together, they can create astonishing real-world experiences and fundamentally new ways to work, travel, exercise, learn, play, and more. Wear these comfortable headphones all day because they're built with premium materials. Listen to your music wirelessly for up to 20 hours of long battery life from a rechargeable lithium-ion battery. Keep listening without battery power by connecting the included audio cable. Unlock more features and access future updates through the Bose Connect app.`,
    },
    {
      id: 6,
      category: `audio`,
      name: `Edifier - S1000MKII 120W Hi-Res Wireless Bookshelf Speaker System - Wood/Black`,
      stock: 12,
      price: 349.99,
      img: `audio6`,
      specs: [
        `High-resolution audio`,
        `5.5" aluminum alloy mid-woofer unit`,
        `45Hz - 40kHz frequency response`,
      ],
      description: `Experience rich sound quality with these Edifier bookshelf speakers. The class D digital power amplification system and a 5.5-inch aluminum mid-woofer unit deliver crystal-clear audio and bass while reducing distortion. These Edifier bookshelf speakers feature wooden side panels for classic appeal, while the support for optical, coaxial and auxiliary inputs ensures flexible connectivity for your TV set, mobile devices and gaming consoles.`,
    },
    {
      id: 7,
      category: `audio`,
      name: `Sonos - One SL Wireless Smart Speaker - White`,
      stock: 2,
      price: 179.99,
      img: `audio7`,
      specs: [
        `Multiroom compatible smart speaker`,
        `2-way speaker system`,
        `Apple AirPlay 2`,
      ],
      description: `Get rich, room-filling sound with this Sonos One SL speaker, and control it with the Sonos app, Apple AirPlay 2, and more. Pair it with Sonos One or another One SL in the same room for stereo separation and more detailed sound.`,
    },
    {
      id: 8,
      category: `audio`,
      name: `Sony - WH-XB900N Wireless Noise Cancelling Over-the-Ear Headphones - Black`,
      stock: 8,
      price: 249.99,
      img: `audio8`,
      specs: [
        `3.5mm gold-plated connector`,
        `Bose Connect app`,
        `Bluetooth 4.2 interface with NFC`,
      ],
      description: `Experience an immersive acoustic performance with these Sony XB Extra Bass wireless headphones. The noise-isolating fit mutes ambient noises, while the lithium-ion battery offers up to 30 hours of playtime, keeping you entertained during long-distance travels. These Sony XB Extra Bass wireless headphones have a touch sensor for convenient control.`,
    },
  ],
};

export default data;
