import React from "react";
import "./style.css";
import { Row } from "antd";
import Item from '../Item/Item'

const ItemsGrid = ({ category, data, cart, setCart }) => {
  const listItems = data.map((item, index) =>
    <Item key={index} data={item} cart={cart} setCart={setCart} />
);
  return (
    <div className="items-container">
      <Row>
        <p className="category-title">{category}</p>
      </Row>
      <Row className="items-grid-row" gutter={[16, 24]}>
        {listItems}
      </Row>
    </div>
  );
};

export default ItemsGrid;
