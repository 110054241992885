import React from "react";
import "./style.css";
import { Col, Button } from "antd";
import {LinkWithParams} from "../LinkWithParams/LinkWithParams";

const Item = ({ data, cart, setCart }) => {
  const img = require(`../../images/${data.category}/${data.id}.jpg`);
  return (
    <Col span={6} xs={24} sm={12} md={8} lg={6}>
      <LinkWithParams to={`/products/${data.category}/${data.id}`}>
        <div className="div-item-img">
          <img className="item-img" src={`${img}`} alt={data.name} />
        </div>
      </LinkWithParams>
      <div className="item-grid">
        <p className="item-title">{data.name}</p>
        <p className="item-stock">
          <span className="span-stock">IN STOCK</span>{" "}
          {`${data.stock} units available`}
        </p>
        <p className="item-price">{`$${data.price}`}</p>
        <ul className="item-specs-list">
          <li className="item-spec">{data.specs[0]}</li>
          <li className="item-spec">{data.specs[1]}</li>
          <li className="item-spec">{data.specs[2]}</li>
        </ul>
        <Button className="add-to-cart-btn" onClick={() => setCart(data)}>
          Add to cart
        </Button>
      </div>
    </Col>
  );
};

export default Item;
