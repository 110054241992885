import React from "react";
import { Link } from "react-router-dom";
import { useQueryParams } from "../../hooks/query-params.hook";

export const LinkWithParams = ({ to, children, ...props }) => {
    const { v2, v1 } = useQueryParams();
    let path = '';
    if (to) {
        if (v2) {
            path = `${to}?v2=true`;
        } else if (v1) {
            path = `${to}?v1=true`;
        }
        else {
            path = to;
        }
    }

    return <Link to={path} {...props}>{children}</Link>
}
