import { Row, Col, Form, Input, Button, Divider } from "antd";
import * as axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import "./style.css";
import { API_BASE_URL, API_KEY } from "../../config/config";
import data from "../../data";
import { useQueryParams } from "../../hooks/query-params.hook";

const generateTransaction = (params) => ({
    externalReferenceId: "demoTest",
    notes: "This is a demo test",
    buyer: {
        email: params.userData.email,
        firstName: params.userData.firstName,
        lastName: params.userData.lastName,
        businessName: "Balance",
        phone: "+972503068586",
    },
    plan: {
        planType: "invoice",
        chargeDate: moment().format("YYYY-MM-DD"),
    },
    lines: [
        {
            shippingPrice: 0,
            tax: 0,
            lineItems: [
                {
                    title: "Computer Products",
                    quantity: 1,
                    productId: "32495966",
                    productSku: "XSF9-44",
                    variationId: "4344444",
                    itemType: "PHISICAL",
                    price: params.subtotal,
                    tax: 0,
                },
            ],
        },
    ],
    allowedPaymentMethods: [
        "creditCard",
        "bank",
        "invoice",
        "payWithTerms",
        'check',
    ],
    allowedTermsPaymentMethods: [
        "creditCard",
        "bank",
        "invoice",
        'check'
    ],
    communicationConfig: {
        emailsTo: [params.userData.email],
        emailsCc: [],
    },
    currency: "USD",
});

const createTransactionForCheckout = (params) => {
    axios
        .post(`${API_BASE_URL}/api/v1/transactions`,
            generateTransaction(params),
            {
                headers: {
                    "x-api-key": API_KEY,
                },
            }
        )
        .then(({data}) => {
            console.log("createTransactionForCheckout -> data", data);
            // invoke the global function with the provided api-key to open the modal
            // with the checkout information
            window.openCheckout(data.token);
        })
        .catch((e) => {
            console.log(e)
            params.setErrorModal(true);
        });
};

const createTransactionForCheckoutV2 = (params) => {
    axios
        .post(`${API_BASE_URL}/api/v1/transactions`,
            generateTransaction(params),
            {
                headers: {
                    "x-api-key": API_KEY,
                },
            }
        )
        .then(({data}) => {
            console.log("createTransactionForCheckout -> data", data);
            // invoke the global function with the provided api-key to open the modal
            // with the checkout information
            window.openCheckoutV2(data.token);
        })
        .catch((e) => {
            params.setErrorModal(true);
        });
};

const createTransactionForCheckoutV2_1 = (params) => {
    axios
        .post(`${API_BASE_URL}/api/v1/transactions`,
            generateTransaction(params),
            {
                headers: {
                    "x-api-key": API_KEY,
                },
            }
        )
        .then(({data}) => {
            console.log("createTransactionForCheckout -> data", data);
            // invoke the global function with the provided api-key to open the modal
            // with the checkout information
            window.openCheckoutV2_1(data.token);
        })
        .catch((e) => {
            params.setErrorModal(true);
        });
};

const CartCheckOut = ({cart, setCart}) => {
    let subtotal,
        shipping = 0;
    const [errorModal, setErrorModal] = useState(false);
    const { email, v2, v1 } = useQueryParams();
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        city: "",
        zipCode: ""
    });

    useEffect(() => {
        if (!email) {
            return;
        }
        const item = {...data.home[0], qty: 1};
        setCart([item]);
        const tempUserData = {
            ...userData, email,
            firstName: "test",
            lastName: "name"
        };
        setUserData(tempUserData);

        setTimeout(() => {
            createTransactionForCheckout({
                userData: tempUserData,
                subtotal: item.price,
                setErrorModal
            });
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const title = "SHOPPING CART";

    if (cart[0]) {
        subtotal = parseFloat(
            cart
                .reduce(function (valorAnterior, valorActual) {
                    return valorAnterior + valorActual.price * valorActual.qty;
                }, 0)
                .toFixed(2)
        );
        shipping = ((1.5 / 100) * subtotal).toFixed(2);
    }

    const listItems = cart.map((item, index) => (
        <Row key={index} className="item-row">
            <Col span={16}>
                <div className="cart-name-row">
                    <div className="cart-img">
                        <img
                            src={require(`../../images/${item.category}/${item.id}.jpg`)}
                            alt={item.name}
                        />
                    </div>
                    <p className="checkout-item-name">{item.name}</p>
                </div>
            </Col>
            <Col className="checkout-item-qty" span={4}>
                <p>{item.qty}</p>
            </Col>
            <Col span={4}>
                <p className="checkout-item-price">
                    {`$${item.price}`}{" "}
                    <Button
                        className="remove-btn"
                        size="small"
                        shape="circle"
                        onClick={() =>
                            setCart(cart.filter((cartItem) => cartItem.name !== item.name))
                        }
                    >
                        -
                    </Button>
                </p>
            </Col>
        </Row>
    ));

    const validateMessages = {
        types: {
            email: "Please enter a valid email",
            string: "Please enter a valid value",
        },
    };

    const onFinish = () => {
        if (v1) {
            createTransactionForCheckout({
                subtotal,
                userData,
                setErrorModal,
            });
            return;
        }
        if (v2) {
            createTransactionForCheckoutV2({
                subtotal,
                userData,
                setErrorModal,
            });
            return;
        }
        createTransactionForCheckoutV2_1({
            subtotal,
            userData,
            setErrorModal,
        });
    };

    console.log(`userData: ${userData.email}`);
    console.log(`name: ${cart[0]?.name}`);

    return (
        <div className="items-container">
            <Row>
                <p className="category-title">{title}</p>
            </Row>

            {!cart[0] && (
                <div className="empty-cart">
                    <p>You haven't added</p>
                    <p>any items to your cart yet</p>
                </div>
            )}

            {cart[0] && (
                <>
                    <div className="cart-container">
                        <Divider className="invoice-divider-small"/>
                        <Row className="items-table-row-names">
                            <Col span={16}>
                                <p className="table-item-title">ITEMS</p>
                            </Col>
                            <Col span={4}>
                                <p className="table-item-title">QTY</p>
                            </Col>
                            <Col span={4}>
                                <p className="table-item-title">PRICE</p>
                            </Col>
                        </Row>

                        {listItems}

                        <Divider className="invoice-divider-small"/>
                        <Row className="user-form-row">
                            <Form
                                size="small"
                                validateMessages={validateMessages}
                                onFinish={onFinish}
                            >
                                <p className="table-item-title">BUYER DETAILS</p>
                                <Form.Item
                                    label="First name"
                                    name={["First name"]}
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        value={userData.firstName}
                                        type="text"
                                        onChange={handleChange}
                                        name="firstName"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Last name"
                                    name={["Last name"]}
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        value={userData.lastName}
                                        type="text"
                                        onChange={handleChange}
                                        name="lastName"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name={["Email"]}
                                    rules={[
                                        {
                                            type: "email",
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        value={userData.email}
                                        type="text"
                                        onChange={handleChange}
                                        name="email"
                                    />
                                </Form.Item>

                                <Divider className="invoice-divider-small"/>
                                <Row>
                                    {" "}
                                    <Col span={12} className="flex-column">
                                        <p className="p-subtotal">Subtotal</p>
                                        <p>Shipping</p>
                                        <p className="p-total">TOTAL</p>
                                    </Col>
                                    <Col span={12} className="flex-column">
                                        <p className="p-bottom subtotal">${subtotal}</p>
                                        <p className="p-bottom shipping">${shipping}</p>
                                        <p className="p-bottom total">${subtotal}</p>
                                    </Col>
                                </Row>
                                {errorModal && (
                                    <Row className="checkout-error-row">
                                        <h2>
                                            Oops! There's an <span className="error-span">error</span>
                                            , please try it again
                                        </h2>
                                    </Row>
                                )}
                                <button className="checkout-btn" type="submit">
                                    <span className="green-bar">/ </span> Checkout{" "}
                                </button>
                            </Form>
                        </Row>
                        <Divider className="invoice-divider-small"/>
                    </div>
                </>
            )}
        </div>
    );
};

export default CartCheckOut;
