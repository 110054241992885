export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.sandbox.getbalance.com";
export const API_KEY = process.env.REACT_APP_API_KEY || "qwed3befb272d1308c2340c50149d96f056997fe3c2dd439";
// export const API_BASE_URL = "http://localhost:3033";
// export const API_KEY = "24077478c04b02e1823f5cbb57f6c74c16beee1173fe5aeb";

export const CHECKOUT_V1_API = process.env.REACT_APP_CHECKOUT_V1_API || "https://checkout.sandbox.getbalance.com";
export const CHECKOUT_V2_API = process.env.REACT_APP_CHECKOUT_V2_API || "https://checkout-v2.sandbox.getbalance.com";

export const CHECKOUT_V1_SDK = `${CHECKOUT_V1_API}/blnceSDK.js`;
export const CHECKOUT_V2_SDK = `${CHECKOUT_V2_API}/sdk.js`;
export const CHECKOUT_V2_1_SDK = `${CHECKOUT_V2_API}/sdk-latest.js`;
