import React, {useState} from "react";
import "./style.css";
import axios from "axios";
import {Row, Form, Input, Divider} from "antd";
import {API_BASE_URL, API_KEY} from "../../config/config";

const title = "Register Buyer";

const validateMessages = {
  types: {
    email: "Please enter a valid email",
    string: "Please enter a valid value",
  },
};

const defaultBuyerData = {
  firstName: "",
  lastName: "",
  email: "",
  businessName: "",
  businessUrl: "",
  phone: "",
  billingAddress: {
    addressLine1: "",
    addressLine2: "",
    countryCode: "",
    city: "",
    state: "",
    zipCode: ""
  }
};
const generateBuyer = (data) => ({
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    businessName: "Balance1",
    businessUrl: "url1",
    phone: "+9725030685861",
    billingAddress: {
      addressLine1: "test 111",
      addressLine2: "test 111",
      countryCode: "IL11",
      city: "Tel-Aviv1",
      state: "21",
      zipCode: "543211"
    }
  });

const tokenScope = {
  scope: "ADD_PAYMENT_METHOD"
};

const registerForPaymentMethod = ({ buyerData, setErrorModal }) => {
  axios.post(`${API_BASE_URL}/api/v1/buyers`,
      generateBuyer(buyerData),
      {
        headers: {
          "x-api-key": API_KEY,
        },
      }
    )
    .then(({data}) => {
      console.log("registerForPaymentMethod -> new buyer data", data);
      axios.post(`${API_BASE_URL}/api/v1/buyers/${data.id}/token`,
        tokenScope,
        {
          headers : {
            "x-api-key" : API_KEY,
          },
        }
      ).then(({data}) => {
        console.log("registerForPaymentMethod -> new token data", data);
        // invoke the global function with the provided api-key to open the modal
        // with the payment method information
        window.openPaymentMethod(data.token);
      })


    })
    .catch((e) => {
      console.log(e)
      setErrorModal(true);
    });
};

const AddPaymentMethod = () => {
  const [errorModal, setErrorModal] = useState(false);
  const [buyerData, setBuyerData] = useState(defaultBuyerData);

  const handleOnFinish = () => {
    registerForPaymentMethod({
      buyerData,
      setErrorModal,
    });
  }

  const handleOnChange = event => {
    const {name, value} = event.target;
    setBuyerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div className="items-container">
      <Row>
        <p className="category-title">{title}</p>
      </Row>

      <div className="buyer-container">
        <Divider className="invoice-divider-small"/>
        <Row className="buyer-form-row">
          <Form
            size="small"
            validateMessages={validateMessages}
            onFinish={handleOnFinish}
          >
            <p className="table-item-title">BUYER DETAILS</p>
            <Form.Item
              label="First name"
              name={["First name"]}
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
            >
              <Input
                value={buyerData.firstName}
                type="text"
                onChange={handleOnChange}
                name="firstName"
              />
            </Form.Item>
            <Form.Item
              label="Last name"
              name={["Last name"]}
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
            >
              <Input
                value={buyerData.lastName}
                type="text"
                onChange={handleOnChange}
                name="lastName"
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name={["Email"]}
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input
                value={buyerData.email}
                type="text"
                onChange={handleOnChange}
                name="email"
              />
            </Form.Item>

            <Divider className="invoice-divider-small"/>

            {errorModal && (
              <Row className="register-error-row">
                <h2>
                  Oops! There's an <span className="error-span">error</span>
                  , please try it again
                </h2>
              </Row>
            )}
            <button className="payment-method-btn" type="submit">
              <span className="green-bar">/ </span> Add Payment Method{" "}
            </button>
          </Form>
        </Row>
        <Divider className="invoice-divider-small"/>
      </div>
    </div>
  );
};

export default AddPaymentMethod;