import React from "react";
import "./style.css";
import { Button } from "antd";
import data from '../../data.js'

const ItemDetail = (props) => {
  let thisData, img = null;
  if (props.match.params.id) thisData = data[props.category][props.match.params.id - 1];
  if (thisData) img = require(`../../images/${thisData.category}/${thisData.id}.jpg`);
  const dataSpecs = thisData.specs.map((spec, index) => (
    <li key={index} className="item-spec-big">{spec}</li>
  ));
  return (
    <div className="detail-container" style={{ marginTop: "16px" }}>
      {thisData.name && (
        <>
          <div className="detail-description">
            <div className="detail-product">
              <div className="detail-image">
                <img src={img} alt="productName" />
              </div>
            </div>
            <div className="detail-buy">
              <p className="detail-title">{thisData.name}</p>
              <p className="detail-price">
                {`$${thisData.price}`}
                <span className="detail-free-shipping">FREE SHIPPING</span>
              </p>
              <p className="">{`Or 6 payments of $${(thisData.price / 6).toFixed(2)}`}</p>
              <ul className="item-specs-details">
                <li className="item-spec-list-header">{`Get it in two days`}</li>
                <li className="item-spec-list-header">{`Pay with Credit Card or Bank Transfer`}</li>
                <li className="item-spec-list-header">{`Certificate guarantee`}</li>
              </ul>
              <Button className="detail-buy-btn" onClick={() => props.setCart(thisData)}>Add to Cart</Button>
            </div>
          </div>
          <div className="detail-text">
            <p className="detail-head-text">PRODUCT DESCRIPTION</p>
            <p className="detail-description-text">{thisData.description}</p>
            <p className="detail-head-text">FEATURES</p>
            <ul className="item-specs-list-description">
                {dataSpecs}
              </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ItemDetail;
